// extracted by mini-css-extract-plugin
export var container = "programmes-module--container--l4PQC";
export var linkButton = "programmes-module--linkButton--FeVR8";
export var blueBtn = "programmes-module--blueBtn--2hND9";
export var blueSimpleBtn = "programmes-module--blueSimpleBtn--3w3wx";
export var bgContainer = "programmes-module--bgContainer--6iZvC";
export var bg = "programmes-module--bg--VxjO1";
export var image = "programmes-module--image--2odoj";
export var opacity = "programmes-module--opacity--21_2h";
export var texts = "programmes-module--texts--35H86";
export var service = "programmes-module--service--efA-d";
export var line = "programmes-module--line--3Mc-h";
export var whiteBtn = "programmes-module--whiteBtn--1MB1-";
export var programsContainer = "programmes-module--programsContainer--3VVl5";
export var programsList = "programmes-module--programsList--3NiD4";
export var programItem = "programmes-module--programItem--3rSti";
export var imageContainer = "programmes-module--imageContainer--3NUcn";
export var buttons = "programmes-module--buttons--1Aa6h";
export var container5 = "programmes-module--container5--gmEqS";
export var innerContainer = "programmes-module--innerContainer--De73F";
export var collabContainer = "programmes-module--collabContainer--WwU1l";