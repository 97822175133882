import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as styles from './programmes.module.scss';

const Services = ({ data }) => {
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);
    const programmesData = [data.programmesJson, data.scholarshipsJson];

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <Layout darkBg={true}>
            <div className={styles.container}>
                <div className={styles.bgContainer}>
                    <div className={styles.bg}>
                        <StaticImage
                            src="../images/programs-header-img2.jpeg"
                            alt="background"
                            className={styles.image}
                            placeholder="blurred"
                        />
                        <div className={styles.opacity}>
                            <div className={styles.texts}>
                                <div className={styles.service}>
                                    <h6>OUR SERVICES</h6>
                                    <div className={styles.line}></div>
                                </div>
                                <h1>
                                    We provide you with the best tech education
                                </h1>
                                <p>
                                    Through our education partners, we provide a
                                    variety of technology courses and
                                    STEM-focused programmes to equip you with
                                    essential digital skills and qualifications
                                    - to uplift you to a better future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.programsContainer}>
                    <h5>Explore Our Programmes</h5>

                    <div className={styles.programsList}>
                        {programmesData.map((programme) => {
                            return (
                                <div className={styles.programItem}>
                                    <div className={styles.imageContainer}>
                                        <GatsbyImage
                                            image={getImage(
                                                programme.hasOwnProperty(
                                                    'imgUrl'
                                                )
                                                    ? programme.imgUrl
                                                    : programme.smallImgURL
                                            )}
                                            alt={
                                                programme.hasOwnProperty(
                                                    'title'
                                                )
                                                    ? programme.title
                                                    : programme.name
                                            }
                                            className={styles.image}
                                            imgStyle={
                                                screen !== 'xs'
                                                    ? {
                                                          borderTopLeftRadius:
                                                              '10px',
                                                          borderBottomLeftRadius:
                                                              '10px'
                                                      }
                                                    : {
                                                          borderTopLeftRadius:
                                                              '10px',
                                                          borderTopRightRadius:
                                                              '10px'
                                                      }
                                            }
                                        />
                                    </div>

                                    <div className={styles.texts}>
                                        <span>
                                            {programme.level === 'professional'
                                                ? 'PROFESSIONAL CERTIFICATION'
                                                : programme.level}
                                        </span>
                                        <h6>
                                            {programme.hasOwnProperty('title')
                                                ? programme.title
                                                : programme.name}
                                        </h6>

                                        {screen === 'xs' ? null : (
                                            <p>
                                                {programme.hasOwnProperty(
                                                    'about'
                                                )
                                                    ? programme.about
                                                    : programme.shortDescription}
                                            </p>
                                        )}

                                        <div className={styles.buttons}>
                                            <Link
                                                to={
                                                    '/programme/' +
                                                    programme.slug
                                                }
                                                className={styles.blueBtn}
                                            >
                                                Learn more
                                            </Link>

                                            <Link
                                                to="/donate"
                                                state={{
                                                    programme: programme.title
                                                }}
                                                className={styles.blueSimpleBtn}
                                            >
                                                Donate
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={styles.container5}>
                    <div className={styles.bg}>
                        <div className={styles.innerContainer}>
                            <div className={styles.texts}>
                                <h5>Interested in joining our cause?</h5>
                                <p>
                                    Learn how you can be our corporate sponsor
                                    or education provider.
                                </p>
                            </div>

                            <div className={styles.buttons}>
                                <Link
                                    className={styles.blueBtn}
                                    to="/contact"
                                    state={{
                                        message:
                                            'Subject: How Can We Participate?'
                                    }}
                                >
                                    Contact Us
                                </Link>
                                <Link
                                    className={styles.blueBtn}
                                    to="/contact"
                                    state={{
                                        message:
                                            'Subject: Interested in Knowing More About DSF'
                                    }}
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Services;

export const ProgrammesQuery = graphql`
    query {
        programmesJson(slug: { eq: "k-12-development-programme" }) {
            about
            imgUrl {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            level
            title
            slug
        }
        scholarshipsJson(slug: { eq: "neumann-fellowship" }) {
            name
            level
            slug
            shortDescription
            smallImgURL {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }
`;
